<template>
    <div class="container" style="font-family: RobotoSlab;">
        <div class="left">
            <!-- <div style="font-size:1.5em;text-align:left;border-bottom:7px solid #4377b9">Browse</div> -->
               <el-menu
      default-active="2"
      class="el-menu-vertical-demo">
      <el-submenu :index="index+1" v-for="(item,index) in bacterialName" :key="index">
        <template slot="title">
          <i class="el-icon-cpu"></i>
          <span style="font-size:1.3em !important">{{item}}</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="1-1" @click="chooseTarget(item)">
              <i class="el-icon-arrow-right"></i>
              <span style="font-size:1.1em">Target</span>
          </el-menu-item> -->
          <el-menu-item index="1-2" @click="chooseMicromolecule(item)">
              <i class="el-icon-arrow-right"></i>
              <span style="font-size:1.3em">Micromolecule</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

    </el-menu>
        </div>
        <div class="right">
              <el-card>
                  <el-table
                  v-loading="loading"
                  :header-cell-style="{background: '#222a35',color:'white'}"
                    v-if="nowMode === 'target'"
                    :data="tableData"
                    stripe
                    style="width: 100%">
                    <el-table-column
                    prop="targetName"
                    label="Target Name"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="geneNames"
                    label="Gene names"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="disease"
                    label="Disease">
                    </el-table-column>
                    <el-table-column
                    prop="uniprotID"
                    label="Uniprot ID">
                    <template slot-scope="scope">
                         <el-link :href="scope.row.link" target="_blank" type="primary">{{scope.row.uniprotID}}</el-link>
                    </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                v-if="nowMode === 'target'"
                    style="display:flex;justify-content:end;padding-top:1%"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="page.pageSize"
                    layout="slot, sizes, prev, pager, next, jumper"
                    :total="page.total">
                    <span style="font-weight:400">Showing {{page.currentPage}}-{{page.currentPage * page.pageSize}} out of {{Number(page.total).toLocaleString()}} records</span>
                </el-pagination>

                <div v-if="nowMode === 'micromolecule'" style="display:flex;justify-content:flex-end;padding-right:1%;padding-bottom:1%;font-size:3rem;"><el-button v-loading.lock="fullscreenLoading"  size="large"  type="primary" icon="el-icon-download" @click="downloadFile">CSV</el-button></div>

                <el-table
                v-loading="loading2"
                    v-if="nowMode === 'micromolecule'"
                    :data="tableData2"
                    :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}"
                    stripe
                    style="width: 100%;font-size:1em;">
                    <el-table-column
                    prop="smiles"
                    :show-overflow-tooltip="true"
                    label="2D Structure"
                    width="200"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <img v-image-preview style="width:100%; max-height:100px;" :src="scope.row.pic" alt="">
                        <!-- <div>
                            {{scope.row.smiles}}
                        </div> -->
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="phase"
                    label="Phase"
                    width="80"
                    align="center"
                    >
                    </el-table-column>
                    <el-table-column
                    property="type"
                    align="center"
                    width="250"
                    label="Activity Type">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.type + '' + scope.row.relation + ''  +scope.row.value + '' + scope.row.units}}
                        </div>
                    </template>
                    </el-table-column>

                    <el-table-column
                    width="500"
                    prop="assayDescription"
                    align="left"
                    label="Assay Description"
                    >
                    </el-table-column>

                    <el-table-column
                    prop="assayType"
                    width="150"
                    align="center"
                    label="Assay Type"
                    >
                    </el-table-column>

                    <el-table-column
                    prop="targetName"
                    width="190"
                    align="center"
                    label="Target Name">
                    </el-table-column>

                    <el-table-column
                    prop="targetOrganism"
                    width="190"
                    align="center"
                    label="Target Organism">
                    </el-table-column>

                    <!-- <el-table-column
                    prop="comment"
                    width="100"
                    label="Comment">
                    </el-table-column> -->

                </el-table>

                <el-pagination
                    v-if="nowMode === 'micromolecule'"
                    style="display:flex;justify-content:end;padding-top:1%"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="page2.currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="page2.pageSize"
                    layout="slot, sizes, prev, pager, next, jumper"
                    :total="page2.total">
                    <span style="font-weight:400;font-size:1em">Showing {{page2.currentPage}}-{{page2.currentPage * page2.pageSize}} out of {{Number(page2.total).toLocaleString()}} records</span>
                </el-pagination>
              </el-card>
        </div>
    </div>
</template>

<script>
export default {
  beforeDestroy () {
    console.log('over')
  },
  created () {
    this.getNames()
    this.onload(this.page)
  },
  methods: {
    downloadFile () {
      this.fullscreenLoading = true
      const a = document.createElement('a')
      console.log(this.BASEURL)
      a.href = this.BASEURL + '/downloadFile/' + this.nowBac + '.csv'
      a.click()
      this.fullscreenLoading = false
    },
    handleCurrentChange (current) {
      this.page.currentPage = current
      this.onload(this.page)
    },
    handleSizeChange (size) {
      this.page.pageSize = size
      this.onload(this.page)
    },
    handleCurrentChange2 (current) {
      this.page2.currentPage = current
      this.onload(this.page2)
    },
    handleSizeChange2 (size) {
      this.page2.pageSize = size
      this.onload(this.page2)
    },
    async getNames () {
      const { data: res } = await this.$http.get('/getBacterialName')
      this.bacterialName = res.result
    },
    async chooseMicromolecule (val) {
    //   const prop = val + '_micromolecule'
      this.nowMode = 'micromolecule'
      this.nowBac = val
      this.onload(this.page)
    //   const { data: res } = await this.$http.post('/getBacterialMicromolecule', { prop: prop })
    //   console.log(res)
    },
    async chooseTarget (val) {
      this.nowBac = val
      this.nowMode = 'target'
      this.onload(this.page)
    //   const prop = val + '_target'
    //   const { data: res } = await this.$http.post('/getBacterialTarget', { prop: prop })
    //   console.log(res)
    },
    onload (page) {
      if (this.nowMode === 'target') {
        this.loading = true
        this.$http.post('/getBacterialTarget', { prop: this.nowBac, page: page }).then(res => {
          if (res.data.return_code === '200') {
            const newList = []
            this.page.total = res.data.total
            res.data.result.forEach(ele => {
              const obj = {}
              obj.targetName = ele[1]
              obj.geneNames = ele[2]
              obj.disease = ele[3]
              obj.uniprotID = ele[4]
              obj.link = ele[5]
              newList.push(obj)
            })
            this.tableData = newList
            this.loading = false
          }
        })
      } else {
        this.loading2 = true
        this.$http.post('/getBacterialMicromolecule', { prop: this.nowBac, page: page }).then(res => {
          if (res.data.return_code === '200') {
            const newList = []
            this.page2.total = res.data.total
            res.data.result.forEach(ele => {
              const obj = {}
              obj.smiles = ele[2]
              obj.phase = ele[1]
              obj.type = ele[3]
              obj.relation = ele[4]
              obj.value = ele[5]
              obj.units = ele[6]
              obj.assayDescription = ele[7]
              obj.assayType = ele[8]
              obj.targetName = ele[9]
              obj.targetOrganism = ele[10]
              obj.comment = ele[11]
              // const url = 'data:image/jpg;base64,' + ele[12]
              const url = 'data:image/svg+xml;base64,' + ele[12]
              obj.pic = url
              newList.push(obj)
            })
            this.tableData2 = newList
            this.loading2 = false
          }
        })
      }
    }
  },
  data () {
    return {
      loading: false,
      fullscreenLoading: false,
      loading2: false,
      nowBac: 'Bacillus anthracis',
      nowMode: 'micromolecule',
      page: {
        currentPage: 1,
        pageSize: 5,
        total: 400
      },
      page2: {
        currentPage: 1,
        pageSize: 5,
        total: 400
      },
      bacterialName: [],
      tableData: [],
      tableData2: [{
        smiles: '',
        phase: '',
        type: '',
        relation: '',
        value: '',
        units: '',
        assayDescription: '',
        assayType: '',
        targetName: '',
        targetOrganism: '',
        comment: ''
      }]
    }
  }
}
</script>

<style scoped>
.container{
    margin: 0%;
    display: flex;
    zoom: 0.9;
}

.left{
    width: 20%;
}

.right{
    width: 80%;
    padding-left: 1%;
}
.el-menu-vertical-demo{
    text-align: left;
    font-size: 1em !important;
    height: 100%;
    overflow-y: scroll;
}
::v-deep .el-table--border::after,.el-table::before{
    background: none !important;
}

::v-deep .el-pagination{
  justify-content: flex-end;
}

::v-deep .el-table .cell{
  word-break: keep-all;
}
</style>
