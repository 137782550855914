import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

Vue.use(VueDirectiveImagePreviewer)
Vue.use(router)
axios.defaults.baseURL = 'https://api.antibacmap.idruglab.cn/'
// axios.defaults.baseURL = 'http://127.0.0.1:5000/'
Vue.prototype.$http = axios
Vue.prototype.BASEURL = 'https://api.antibacmap.idruglab.cn'
// Vue.prototype.BASEURL = 'http://127.0.0.1:5000'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
