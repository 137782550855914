import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mainContent from '../components/main.vue'
import contactContent from '../components/contact.vue'
import searchText from '../components/searchText.vue'
import searchStructure from '../components/searchStructure.vue'
import browse from '../components/browse.vue'
import download from '../components/download.vue'
import evaluation from '../components/evaluation.vue'
import evaluationTarget from '../components/evaluationTarget.vue'
import result from '../components/result.vue'
import resultTarget from '../components/resultTarget.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: mainContent },
      { path: '/contact', component: contactContent },
      { path: '/searchText', component: searchText },
      { path: '/searchStructure', component: searchStructure },
      { path: '/browse', component: browse },
      { path: '/download', component: download },
      { path: '/evaluation', component: evaluation },
      { path: '/evaluationTarget', component: evaluationTarget },
      { path: '/result', component: result },
      { path: '/resultTarget', component: resultTarget }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
