<template>
    <div class="container" style="margin-top:200px;">
       <div class="left">
            <el-collapse style="border:0px" v-model="activeName" @change="changeContent">
        <el-collapse-item style="border:0px;font-family: RobotoSlab;" name="1">
            <template slot="title">
            <svg style="padding-right:3%" t="1649395140170" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1566" width="16" height="16"><path d="M960 287.8H64c-35.3 0-64-28.7-64-64s28.7-64 64-64h896c35.3 0 64 28.7 64 64s-28.7 64-64 64zM960 576H64c-35.3 0-64-28.7-64-64s28.7-64 64-64h896c35.3 0 64 28.7 64 64s-28.7 64-64 64zM960 864.3H64c-35.3 0-64-28.7-64-64s28.7-64 64-64h896c35.3 0 64 28.7 64 64s-28.7 64-64 64z" p-id="1567"></path></svg>
            <p style="font-size:3em;font-weight:500">What's the AntibacMap?</p>
            </template>
            <p class="content" style="font-size:1.7em;font-weight:300">Drug development is contingent on the availability of appropriate drug candidates and therapeutic targets. We created the AntibacMap platform, which is based on a database of antibacterial small molecule medications and provides a user-friendly, publicly accessible Web interface for antibacterial pharmaceuticals. AntibacMap facilitates the identification of antimicrobial drugs by providing experimentally confirmed data on the bioactivity of small antimicrobial compounds as well as related antimicrobial target information.
</p>
        </el-collapse-item>
        </el-collapse>
       </div>
       <div class="right" style="margin-left:40px; margin-top:40px;">
           <div class="demo-image">
            <div class="block" >
                <el-image
                :src="pic"></el-image>
            </div>
       </div>
    </div>
    </div>
</template>

<script>
import Image from '../assets/Images/pic1.png'

export default {
  data () {
    return {
      activeName: '1',
      pic: Image
    }
  }
}
</script>

<style scoped>
.left{
    width: 60%;height: 100%;
}

.right{
    width: 40%;height: 100%;
}

.container{
    margin: 5% 10% 5% 10%;
    display: flex;
    justify-content: space-around;
}

.content{
    text-align: left;
    word-break: break-all;
    text-indent: 2.5%;
}
</style>
