<template>
    <div class="container" style="font-family: RobotoSlab;">
        <el-card>
              <el-table
                :data="tableData"
                :header-cell-style="{background: '#222a35',color:'white',padding: '7px 6px', fontSize: '1.2rem',fontWeight: '400'}"
                :cell-style="{padding: '5px'}"
                stripe
                style="width: 100%">
                <el-table-column
                prop="fileName"
                label="File Name">
                </el-table-column>
                <el-table-column
                prop="link"
                label="" align="right" padding-right="20px">
                <template slot-scope="scope">
                    <div>
                         <el-link :href="scope.row.link" type="primary">Download&nbsp;&nbsp;</el-link>
                    </div>
                </template>
                </el-table-column>
                <!-- <el-table-column
                prop="version"
                width="200"
                label="Version Number">
                </el-table-column>
                <el-table-column
                prop="createT"
                label="Create Date">
                </el-table-column> -->
            </el-table>
        </el-card>
    </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        fileName: 'Antibac_drug.csv',
        link: this.BASEURL + '/downloadFile/antibac_drug.csv',
        version: '1.0',
        createT: '2022.04'
      },
      // // {
      // //   fileName: 'Actinobacillus pleuropneumoniae.h5',
      // //   link: this.BASEURL + '/downloadFile/Actinobacillus pleuropneumoniae.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Aeromonas hydrophila.h5',
      //   link: this.BASEURL + '/downloadFile/Aeromonas hydrophila.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Bacillus anthracis.h5',
      //   link: this.BASEURL + '/downloadFile/Bacillus anthracis.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Bordetella bronchiseptica.h5',
      //   link: this.BASEURL + '/downloadFile/Bordetella bronchiseptica.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Burkholderia pseudomallei.h5',
      // //   link: this.BASEURL + '/downloadFile/Burkholderia pseudomallei.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Campylobacter jejuni.h5',
      //   link: this.BASEURL + '/downloadFile/Campylobacter jejuni.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Chlamydia trachomatis.h5',
      //   link: this.BASEURL + '/downloadFile/Chlamydia trachomatis.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Chlamydophila pneumoniae.h5',
      // //   link: this.BASEURL + '/downloadFile/Chlamydophila pneumoniae.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Clostridium perfringens.h5',
      //   link: this.BASEURL + '/downloadFile/Clostridium perfringens.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Clostridium tetani.h5',
      // //   link: this.BASEURL + '/downloadFile/Clostridium tetani.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Corynebacterium diphtheriae.h5',
      // //   link: this.BASEURL + '/downloadFile/Corynebacterium diphtheriae.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Edwardsiella tarda.h5',
      // //   link: this.BASEURL + '/downloadFile/Edwardsiella tarda.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Escherichia coli.h5',
      //   link: this.BASEURL + '/downloadFile/Escherichia coli.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Flavobacterium columnare.h5',
      // //   link: this.BASEURL + '/downloadFile/Flavobacterium columnare.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Haemophilus influenzae.h5',
      //   link: this.BASEURL + '/downloadFile/Haemophilus influenzae.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Helicobacter pylori.h5',
      //   link: this.BASEURL + '/downloadFile/Helicobacter pylori.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Listeria monocytogenes.h5',
      // //   link: this.BASEURL + '/downloadFile/Listeria monocytogenes.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Mannheimia haemolytica.h5',
      // //   link: this.BASEURL + '/downloadFile/Mannheimia haemolytica.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'MRSA.h5',
      // //   link: this.BASEURL + '/downloadFile/MRSA.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Mycobacterium avium.h5',
      // //   link: this.BASEURL + '/downloadFile/Mycobacterium avium.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Mycobacterium bovis.h5',
      // //   link: this.BASEURL + '/downloadFile/Mycobacterium bovis.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Mycobacterium marinum.h5',
      // //   link: this.BASEURL + '/downloadFile/Mycobacterium marinum.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Mycobacterium tuberculosis.h5',
      //   link: this.BASEURL + '/downloadFile/Mycobacterium tuberculosis.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Mycoplasma gallisepticum.h5',
      // //   link: this.BASEURL + '/downloadFile/Mycoplasma gallisepticum.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Neisseria meningitidis.h5',
      //   link: this.BASEURL + '/downloadFile/Neisseria meningitidis.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Pasteurella multocida.h5',
      // //   link: this.BASEURL + '/downloadFile/Pasteurella multocida.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Pseudomonas aeruginosa.h5',
      //   link: this.BASEURL + '/downloadFile/Pseudomonas aeruginosa.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Shigella.h5',
      //   link: this.BASEURL + '/downloadFile/Shigella.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Staphylococcus aureus.h5',
      //   link: this.BASEURL + '/downloadFile/Staphylococcus aureus.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Streptococcus agalactiae.h5',
      // //   link: this.BASEURL + '/downloadFile/Streptococcus agalactiae.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Streptococcus equi.h5',
      //   link: this.BASEURL + '/downloadFile/Streptococcus equi.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // {
      //   fileName: 'Streptococcus pneumoniae.h5',
      //   link: this.BASEURL + '/downloadFile/Streptococcus pneumoniae.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Streptococcus pyogenes.h5',
      // //   link: this.BASEURL + '/downloadFile/EStreptococcus pyogenes.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Streptococcus suis.h5',
      // //   link: this.BASEURL + '/downloadFile/Streptococcus suis.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Vibrio cholerae.h5',
      //   link: this.BASEURL + '/downloadFile/Vibrio cholerae.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      // // {
      // //   fileName: 'Vibrio vulnificus.h5',
      // //   link: this.BASEURL + '/downloadFile/Vibrio vulnificus.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Yersinia enterocolitica.h5',
      // //   link: this.BASEURL + '/downloadFile/Yersinia enterocolitica.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // // {
      // //   fileName: 'Yersinia pestis.h5',
      // //   link: this.BASEURL + '/downloadFile/Yersinia pestis.h5',
      // //   version: '1.0',
      // //   createT: '2022.04'
      // // },
      // {
      //   fileName: 'Yersinia pseudotuberculosis.h5',
      //   link: this.BASEURL + '/downloadFile/Yersinia pseudotuberculosis.h5',
      //   version: '1.0',
      //   createT: '2022.04'
      // },
      {
        fileName: 'Bacillus anthracis-CHEMBL209.pkl',
        link: this.BASEURL + '/downloadFileNew/Bacillus anthracis-CHEMBL209.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Bacillus anthracis-CHEMBL4372.pkl',
        link: this.BASEURL + '/downloadFileNew/Bacillus anthracis-CHEMBL4372.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Bacillus anthracis-CHEMBL5396.pkl',
        link: this.BASEURL + '/downloadFileNew/Bacillus anthracis-CHEMBL5396.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Bacillus anthracis-CHEMBL5804.pkl',
        link: this.BASEURL + '/downloadFileNew/Bacillus anthracis-CHEMBL5804.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Bacillus anthracis-CHEMBL1293266.pkl',
        link: this.BASEURL + '/downloadFileNew/Bacillus anthracis-CHEMBL1293266.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Clostridium perfringens-CHEMBL5189.pkl',
        link: this.BASEURL + '/downloadFileNew/Clostridium perfringens-CHEMBL5189.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Clostridium perfringens-CHEMBL5192.pkl',
        link: this.BASEURL + '/downloadFileNew/Clostridium perfringens-CHEMBL5192.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL1809.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL1809.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL1826.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL1826.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL2065.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL2065.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL6137.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL6137.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL1795101.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL1795101.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL1795135.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL1795135.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL2094139.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL2094139.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Escherichia coli-CHEMBL4106139.pkl',
        link: this.BASEURL + '/downloadFileNew/Escherichia coli-CHEMBL4106139.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL1849.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL1849.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL1857.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL1857.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL2361.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL2361.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL3640.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL3640.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL5630.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL5630.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL5631.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL5631.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL5662.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL5662.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL5767.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL5767.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL6069.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL6069.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL1908385.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL1908385.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Mycobacterium tuberculosis-CHEMBL4295521.pkl',
        link: this.BASEURL + '/downloadFileNew/Mycobacterium tuberculosis-CHEMBL4295521.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Pseudomonas aeruginosa-CHEMBL1293244.pkl',
        link: this.BASEURL + '/downloadFileNew/Pseudomonas aeruginosa-CHEMBL1293244.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Pseudomonas aeruginosa-CHEMBL1293246.pkl',
        link: this.BASEURL + '/downloadFileNew/Pseudomonas aeruginosa-CHEMBL1293246.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Pseudomonas aeruginosa-CHEMBL1641350.pkl',
        link: this.BASEURL + '/downloadFileNew/Pseudomonas aeruginosa-CHEMBL1641350.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL3994.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL3994.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL4088.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL4088.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL4114.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL4114.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL5440.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL5440.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL6187.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL6187.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL1681620.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL1681620.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL1921666.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL1921666.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL2163181.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL2163181.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL2424506.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL2424506.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL3038482.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL3038482.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Staphylococcus aureus-CHEMBL3038508.pkl',
        link: this.BASEURL + '/downloadFileNew/Staphylococcus aureus-CHEMBL3038508.pkl',
        version: '1.0',
        createT: '2022.04'
      },
      {
        fileName: 'Vibrio cholerae-CHEMBL3832641.pkl',
        link: this.BASEURL + '/downloadFileNew/Vibrio cholerae-CHEMBL3832641.pkl',
        version: '1.0',
        createT: '2022.04'
      }]
    }
  }
}
</script>

<style scoped>
.container{
    width: 50%;
    margin: 5% auto;
    font-size: 1.2em;
}

.name{
    display: flex;
    padding: 1% 0 3% 2%;
    font-size: 3rem;
}

.email{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}

.address{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}

.Institution{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}
</style>
