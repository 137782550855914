<template>
    <el-container>
      <el-header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#222a35"
          text-color="#fff"
          style="font-size:1.3rem; font-family: RobotoSlab;"
          active-text-color="#ffd04b">
          <el-menu-item index="0" class="title">AntibacMap</el-menu-item>
          <el-menu-item index="1" class="titleItem" style="padding-left:10%" @click="toHome">
            Home
          </el-menu-item>
          <!-- <el-submenu index="2">
            <template slot="title" style="font-size:1.0em">Search</template>
            <el-menu-item index="2-1" @click="toSearchText">Text</el-menu-item>
            <el-menu-item index="2-2"  @click="toSearchStructure">Structure</el-menu-item>
          </el-submenu>
          <el-menu-item index="3" class="titleItem" @click="toBrowse">Browse</el-menu-item> -->
          <el-submenu index="4">
            <template slot="title" style="font-size:1.0em">Evaluation</template>
            <el-menu-item index="4-1" @click="toEvaluation">Bacteria</el-menu-item>
            <el-menu-item index="4-2"  @click="toEvaluationTarget">Target</el-menu-item>
          </el-submenu>
          <el-menu-item index="5" class="titleItem" @click="toDownload">Download</el-menu-item>
          <el-menu-item index="6" class="titleItem" @click="toContact">Contact</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    <el-footer>
      <div class="footerItem">
        <span class="footerItemText" v-text="copyRight"></span>
        <span class="footerItemText" v-text="createdName"></span>
      </div>
    </el-footer>
    </el-container>
</template>

<script>
// @ is an alias to /src

export default {
  mounted () {
    this.activeIndex = window.localStorage.getItem('activeIndex')
  },
  data () {
    return {
      activeIndex: '1',
      copyRight: 'Copyright © 2023  Ed Xia  All rights reserved.',
      createdName: 'Created by Ed Xia '
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      this.activeIndex = key + ''
      window.localStorage.setItem('activeIndex', key + '')
    },
    toDownload () {
      this.$router.push('./download')
    },
    toContact () {
      this.$router.push('./contact')
    },
    toHome () {
      this.$router.push('./welcome')
    },
    toSearchText () {
      this.$router.push('./searchText')
    },
    toSearchStructure () {
      this.$router.push('./searchStructure')
    },
    toBrowse () {
      this.$router.push('./browse')
    },
    toEvaluation () {
      this.$router.push('./evaluation')
    },
    toEvaluationTarget () {
      this.$router.push('./evaluationTarget')
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: SegoeScript;
  src: url("../assets/Fonts/segoesc.ttf");
}

@font-face {
  font-family: RobotoSlab;
  src: url("../assets/Fonts/RobotoSlab.ttf");
}
.title{
  font-family: SegoeScript !important;
  padding: 0 10% 0 10%;
  font-size: 1.7em;
}

.titleItem{
  padding: 0 2% 0 2%;
  font-size: 1.0em;
}

.el-header, .el-footer {
  text-align: center;
  line-height: 60px;
  position: absolute;
  left: 0;
  right: 0;
}

.el-header {
    top: 0;
    padding: 0;
  }

.el-footer {
  background: #222a35;
  bottom: 0;
}

.el-main {
  background-color: white;
  color: #333;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 60px;
}

.footerItem{
  display: flex;
  justify-content: space-between;
  padding: 0 4% 0 4%;
}

.footerItemText{
  color: white;
  font-family: RobotoSlab;
}
.el-menu-item{
  font-family: RobotoSlab;
}

::v-deep .el-submenu__title{
  font-size: 1em !important;
}
</style>
