<template>
    <div class="container" style="font-family: RobotoSlab;">
        <div class="search">
          <div class="left" style="display:inline-block">
            <el-input style="font-family: RobotoSlab;font-size:1.5rem; border:1px solid #2f528f;border-radius:6px;box-shadow: 0 0 5px #2f528f;" type="textarea" resize="none" :rows="1" v-model="mol" placeholder="Entering..."></el-input>
        </div>
        <div class="right" style="display:inline-block">
            <el-button size="large" style="font-family: RobotoSlab; border:1px solid #2f528f;border-radius:6px;box-shadow: 0 0 5px #2f528f;font-size:1.5rem;" @click="query">Submit</el-button>
        </div>
        </div>
         <div class="container2" v-if="dialogTableVisible">
           <div class="left2">
             <el-menu
                default-active="1"
                class="el-menu-vertical-demo">
                <el-menu-item style="font-size:0.8em" index="1" @click="changeMode('target')">
                  <i class="el-icon-arrow-right"></i>
                  <span slot="title" >Target</span>
                </el-menu-item>
                <el-menu-item style="font-size:0.8em" index="2" @click="changeMode('micromolecule')">
                  <i class="el-icon-arrow-right"></i>
                  <span slot="title" >Micromolecule</span>
                </el-menu-item>

              </el-menu>
           </div>
           <div class="right2">
              <el-table style="margin-top: 10px;" :data="data" v-if="nowMode === 'target'"  :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}">
            <el-table-column
            width="200"
                    property="name"
                    align="center"
                    label="Name">
             </el-table-column>
            <el-table-column
              property="targetName"
              label="Target Name"
            width="450"
              >
              </el-table-column>
              <el-table-column
              property="geneNames"
              label="Gene names"
            width="450"
              >
              </el-table-column>
              <el-table-column
            width="400"
              property="disease"
              label="Disease">
              </el-table-column>
              <el-table-column
            width="200"
              property="uniprotID"
              align="center"
              label="Uniprot ID">
              <template slot-scope="scope">
                    <el-link :href="scope.row.link" target="_blank" type="primary">{{scope.row.uniprotID}}</el-link>
              </template>
              </el-table-column>
          </el-table>

          <el-pagination
                v-if="nowMode === 'target'"
                    style="display:flex;justify-content:end;padding-top:1%;font-size:1em"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="page.pageSize"
                    layout="slot, sizes, prev, pager, next, jumper"
                    :total="page.total">
                    <span style="font-weight:400;font-size:1em">Showing {{page.currentPage}}-{{page.currentPage * page.pageSize}} out of {{Number(page.total).toLocaleString()}} records</span>
          </el-pagination>
          <div v-if="nowMode === 'micromolecule'" style="display:flex;justify-content:flex-end;padding-right:1%"><el-button style="font-size:1em" v-loading.lock="fullscreenLoading"  size="large"  type="primary" icon="el-icon-download" @click="downloadFile">csv</el-button></div>
          <el-table
                v-loading="loading2"
                    v-if="nowMode === 'micromolecule'"
                    :data="data2"
                    :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}"
                    style="margin-top: 10px">
                    <el-table-column
                    property="name"
                    align="center"
                    width="200"
                    label="Name">
                    </el-table-column>
                    <el-table-column
                    property="smiles"
                    width="180"
                    label="2D Structure"
                    :show-overflow-tooltip="true"
                    >
                    <template slot-scope="scope">
                        <img v-image-preview style="width:100%; max-height:100px;" :src="scope.row.pic" alt="">
                        <!-- <div>
                            {{scope.row.smiles}}
                        </div> -->
                    </template>
                    </el-table-column>
                    <el-table-column
                    width="100"
                    property="phase"
                    label="Phase"
                    align="center"
                    >
                    </el-table-column>
                    <el-table-column
                    property="type"
                    width="200"
                    align="center"
                    label="Activity Type">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.type + '' + scope.row.relation + ''  +scope.row.value + '' + scope.row.units}}
                        </div>
                    </template>
                    </el-table-column>
                    <!-- <el-table-column
                    property="relation"
                    align="center"
                    label="Relation">
                    </el-table-column>

                    <el-table-column
                    property="value"
                    align="center"
                    label="Value">
                    </el-table-column>\

                    <el-table-column
                    property="units"
                    align="center"
                    label="Units">
                    </el-table-column> -->

                    <el-table-column
                    width="400"
                    property="assayDescription"
                    label="Assay Description">
                    </el-table-column>

                    <el-table-column
                    property="assayType"
                    width="150"
                    align="center"
                    label="Assay Type">
                    </el-table-column>

                    <el-table-column
                    property="targetName"
                    width="225"
                    align="center"
                    label="Target Name">
                    </el-table-column>

                    <el-table-column
                    property="targetOrganism"
                    width="225"
                    align="center"
                    label="Target Organism">
                    </el-table-column>

                    <!-- <el-table-column
                    property="comment"
                    width="100"
                    label="Comment">
                    </el-table-column> -->

                </el-table>

          <el-pagination
              v-if="nowMode === 'micromolecule'"
              style="display:flex;justify-content:end;padding-top:1%"
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="page2.currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="page2.pageSize"
              layout="slot, sizes, prev, pager, next, jumper"
              :total="page.total">
              <span style="font-weight:400;font-size:1em">Showing {{page2.currentPage}}-{{page2.currentPage * page2.pageSize}} out of {{Number(page2.total).toLocaleString()}} records</span>
          </el-pagination>
           </div>
         </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      fullscreenLoading: false,
      mol: '',
      data: [],
      data2: [],
      dialogTableVisible: false,
      nowBac: '',
      nowMode: '',
      loading: false,
      page: {
        currentPage: 1,
        pageSize: 5,
        total: 400
      },
      page2: {
        currentPage: 1,
        pageSize: 5,
        total: 400
      }
    }
  },
  methods: {
    downloadFile () {
      this.fullscreenLoading = true
      const a = document.createElement('a')
      console.log(this.BASEURL)
      a.href = this.BASEURL + '/downloadFile/' + this.nowBac + '.csv'
      a.click()
      this.fullscreenLoading = false
    },
    handleClick (val) {
      this.onload(this.page2)
    },
    changeMode (val) {
      this.nowMode = val
      if (this.nowMode === 'target') {
        this.onload(this.page)
      } else {
        this.onload(this.page2)
      }
    },
    query () {
      if (this.mol === '') {
        return this.$message.error('Please enter bacterium')
      }
      this.nowMode = 'target'
      this.nowBac = this.mol
      this.dialogTableVisible = true
      this.onload(this.page)
    },
    handleCurrentChange (current) {
      this.page.currentPage = current
      this.onload(this.page)
    },
    handleSizeChange (size) {
      this.page.pageSize = size
      this.onload(this.page)
    },
    handleCurrentChange2 (current) {
      this.page2.currentPage = current
      this.onload(this.page2)
    },
    handleSizeChange2 (size) {
      this.page2.pageSize = size
      this.onload(this.page2)
    },
    onload (page) {
      if (this.nowMode === 'target') {
        this.loading = true
        this.$http.post('/getBacterialTarget', { prop: this.nowBac, page: page }).then(res => {
          if (res.data.return_code === '200') {
            const newList = []
            this.page.total = res.data.total
            res.data.result.forEach(ele => {
              const obj = {}
              obj.name = ele[0]
              obj.targetName = ele[1]
              obj.geneNames = ele[2]
              obj.disease = ele[3]
              obj.uniprotID = ele[4]
              obj.link = ele[5]
              newList.push(obj)
            })
            this.data = newList
            this.loading = false
          }
        })
      } else {
        this.loading2 = true
        this.$http.post('/getBacterialMicromolecule', { prop: this.nowBac, page: page }).then(res => {
          if (res.data.return_code === '200') {
            const newList = []
            this.page2.total = res.data.total
            res.data.result.forEach(ele => {
              const obj = {}
              obj.name = ele[0]
              obj.smiles = ele[2]
              obj.phase = ele[1]
              obj.type = ele[3]
              obj.relation = ele[4]
              obj.value = ele[5]
              obj.units = ele[6]
              obj.assayDescription = ele[7]
              obj.assayType = ele[8]
              obj.targetName = ele[9]
              obj.targetOrganism = ele[10]
              obj.comment = ele[11]
              const url = 'data:image/svg+xml;base64,' + ele[12]
              obj.pic = url
              newList.push(obj)
            })
            this.data2 = newList
            this.loading2 = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    margin: 10% auto;
    zoom: 0.9;

}

.search{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left{
    width: 50%;
    padding-right: 2%;
}

.container2{
  padding-top: 2%;
  border: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  width: 100%;
  display: flex;
  font-size: 1.2em !important;
}

.left2{
  width: 16%;
  font-size: 1.5rem;
}

.right2{
  width: 84%;
}

.el-menu-vertical-demo{
    text-align: left;
    font-size: 1.3em !important;
}
::v-deep .el-pagination{
  justify-content: flex-end;
}

::v-deep .el-table .cell{
  word-break: keep-all;
}
</style>
