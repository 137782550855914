<template>
  <div class="container" style="font-family: RobotoSlab;">
      <div class="left">
          <div class="leftTitle">
              <div style="margin-bottom:4%;height:50px;display:flex;align-items:center;border:1px solid #222a35;border-radius:10px;background:#222a35;color:white">
                  <svg t="1649698852303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2199" width="48" height="48"><path d="M512 624a112 112 0 1 0 0-224 112 112 0 0 0 0 224z" p-id="2200" fill="#ffffff"></path></svg>
              <span style="font-size:1.2em">Models</span>
              </div>
              <el-radio-group v-model="radio">
                 <el-radio :label="'all'" size="large" style="font-size:2rem;display:flex;align-items:center;padding-bottom:5%;padding-top:5%" >Select All <span style="font-size:0.8rem;margin-left:5%;color:#909399"><i style="color:#409EFF" class="el-icon-warning"></i>Prohibit uploading files</span></el-radio>
              <el-radio :label="'single'" size="large" style="font-size:2rem;display:flex;align-items:center;padding-bottom:5%;" >Select Single</el-radio>
              <!-- <el-radio size="medium" style="font-size:1.5em;display:flex" :id="'radio' + index" class="radio" :label="item" v-for="item,index in models" :key="index">{{item}}</el-radio> -->
          </el-radio-group>
                 <el-select size="large" style="width:100%;font-size:1.2em;font-family: RobotoSlab; font-weight: 300;" :disabled="radio != 'single'" v-model="modelName">
                  <el-option
                  style="font-family: RobotoSlab; font-weight: 300; font-size:1.2em;"
                    v-for="item in models"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
           </div>
      </div>
      <div class="right">
          <div class="rightTitle1">
              <div style="margin-bottom:2%;height:30px;display:flex;align-items:center;border:1px solid #222a35;border-radius:10px;background:#222a35;color:white">
                  <svg t="1649698852303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2199" width="24" height="24"><path d="M512 624a112 112 0 1 0 0-224 112 112 0 0 0 0 224z" p-id="2200" fill="#ffffff"></path></svg>
              <span style="font-size:0.8em">By Drawing Molecule from Editor Below</span>
              </div>
          </div>
          <div class="jsme">
              <iframe id="text" src="/static/index.html" width="100%" height="360px" frameborder="0"></iframe>
          </div>
          <div class="rightTitle2">
              <div style="margin-bottom:2%;height:30px;display:flex;align-items:center;border:1px solid #222a35;border-radius:10px;background:#222a35;color:white">
                  <svg t="1649698852303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2199" width="24" height="24"><path d="M512 624a112 112 0 1 0 0-224 112 112 0 0 0 0 224z" p-id="2200" fill="#ffffff"></path></svg>
              <span style="font-size:0.8em">By Inputting SMILES</span>
              </div>
          </div>
          <div class="inputSmiles">
              <!-- <div style="text-align:left">SMILES:</div> -->
              <div>
                  <el-input size="large" v-model="smile"></el-input>
              </div>
          </div>
          <div class="rightTitle3" style="padding-top:4%">
              <div style="margin-top:-2%;margin-bottom:2%;height:30px;display:flex;align-items:center;border:1px solid #222a35;border-radius:10px;background:#222a35;color:white">
                  <svg t="1649698852303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2199" width="24" height="24"><path d="M512 624a112 112 0 1 0 0-224 112 112 0 0 0 0 224z" p-id="2200" fill="#ffffff"></path></svg>
              <span style="font-size:0.8em">By Uploading Files(*.csv) limit=200</span>
              </div>
          </div>
          <div class="upload" style="text-align:left; font-size:1rem">
              <el-upload
                  style="text-align:left"
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :before-remove="beforeRemove"
                  :before-upload="beforeUpload"
                  accept=".csv"
                  :limit="1">
                  <el-button size="large" style="background:#1b222a;color:white;text-align:left;font-size:1em" >Click to upload</el-button>
                  <div slot="tip" class="el-upload__tip" style="text-align:left;font-size:0.9em !important">Only for csv file and quantity of mols not more than 200</div>
              </el-upload>
              <el-link type="primary" style="text-align:left;font-size:0.9em" href="https://api.antibacmap.idruglab.cn/download/Example.csv">Download Example.csv</el-link>
          </div>
          <div class="selectMethod" style="padding-top:4%">
              <div style="margin-bottom:2%;height:30px;display:flex;align-items:center;border:1px solid #222a35;border-radius:10px;background:#222a35;color:white">
                  <svg t="1649698852303" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2199" width="24" height="24"><path d="M512 624a112 112 0 1 0 0-224 112 112 0 0 0 0 224z" p-id="2200" fill="#ffffff"></path></svg>
              <span style="font-size:0.8em">Select the Data Source</span>
              </div>
          </div>
          <div class="select" style="text-align:left;padding:1% 0;">
                <el-select size="large" v-model="method" style="font-size:1.5em !important;width:100%">
                      <el-option
                      style="font-family: RobotoSlab; font-weight: 300; font-size:1.2em;"
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                      </el-option>
                  </el-select>
          </div>
          <div class="btn" style="text-align:right;padding-top:1%">
              <el-button v-loading.fullscreen.lock="fullscreenLoading" size="large" style="background:#1c232b;color:white;font-size:1em" @click="submit">Submit</el-button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  watch: {
    method (newV) {
      if (newV === '3' && this.radio === 'all') {
        this.method = '1'
        return this.$message.error('Methods of Select All which does not support upload file.')
      }
    }
  },
  created () {
    window.getFromIframe = this.getFromIframe
  },
  data () {
    return {
      fullscreenLoading: false,
      data: [],
      fileList: [],
      method: '1',
      mol: '',
      smile: '',
      selectAll: false,
      radio: 'single',
      modelName: 'Bacillus anthracis-CHEMBL209',
      models: ['Bacillus anthracis-CHEMBL209',
        'Bacillus anthracis-CHEMBL5396',
        'Bacillus anthracis-CHEMBL5804',
        'Bacillus anthracis-CHEMBL1293266',
        'Bacillus anthracis-CHEMBL4372',
        'Clostridium perfringens-CHEMBL5192',
        'Clostridium perfringens-CHEMBL5189',
        'Escherichia coli-CHEMBL1826',
        'Escherichia coli-CHEMBL1809',
        'Escherichia coli-CHEMBL1795101',
        'Escherichia coli-CHEMBL4106139',
        'Escherichia coli-CHEMBL2094139',
        'Escherichia coli-CHEMBL6137',
        'Escherichia coli-CHEMBL1795135',
        'Escherichia coli-CHEMBL2065',
        'Mycobacterium tuberculosis-CHEMBL5662',
        'Mycobacterium tuberculosis-CHEMBL5767',
        'Mycobacterium tuberculosis-CHEMBL6069',
        'Mycobacterium tuberculosis-CHEMBL1857',
        'Mycobacterium tuberculosis-CHEMBL5630',
        'Mycobacterium tuberculosis-CHEMBL2361',
        'Mycobacterium tuberculosis-CHEMBL5631',
        'Mycobacterium tuberculosis-CHEMBL4295521',
        'Mycobacterium tuberculosis-CHEMBL1849',
        'Mycobacterium tuberculosis-CHEMBL1908385',
        'Mycobacterium tuberculosis-CHEMBL3640',
        'Pseudomonas aeruginosa-CHEMBL1293246',
        'Pseudomonas aeruginosa-CHEMBL1293244',
        'Pseudomonas aeruginosa-CHEMBL1641350',
        'Staphylococcus aureus-CHEMBL6187',
        'Staphylococcus aureus-CHEMBL3994',
        'Staphylococcus aureus-CHEMBL1921666',
        'Staphylococcus aureus-CHEMBL4088',
        'Staphylococcus aureus-CHEMBL2163181',
        'Staphylococcus aureus-CHEMBL3038482',
        'Staphylococcus aureus-CHEMBL4114',
        'Staphylococcus aureus-CHEMBL3038508',
        'Staphylococcus aureus-CHEMBL1681620',
        'Staphylococcus aureus-CHEMBL2424506',
        'Staphylococcus aureus-CHEMBL5440',
        'Vibrio cholerae-CHEMBL3832641'],
      options: [{
        value: '1',
        label: 'By Drawing Molecule from Editor Below'
      },
      {
        value: '2',
        label: 'By Inputting SMILES'
      },
      {
        value: '3',
        label: 'By Uploading Files(*.csv)'
      }]
    }
  },
  methods: {
    submit () {
      if (this.method === '1') {
        if (this.radio === 'single') {
          if (this.mol === '') {
            return this.$message.error('Please create a mol by JSME Editor')
          } else {
            const data = {}
            data.model = this.modelName
            data.jsmeSmile = this.mol
            data.option = this.options[Number(this.method) - 1].label
            this.fullscreenLoading = true
            this.$http.post('/resultTarget', { data: data }).then(res => {
              this.data = res.data
              this.fullscreenLoading = false
              this.$router.push({ path: '/resultTarget', query: { data: this.data } })
            })
          }
        } else {
          if (this.mol === '') {
            return this.$message.error('Please create a mol by JSME Editor')
          } else {
            const data = {}
            data.model = this.radio
            data.jsmeSmile = this.mol
            data.option = this.options[Number(this.method) - 1].label
            this.fullscreenLoading = true
            this.$http.post('/resultTarget', { data: data }).then(res => {
              this.data = res.data
              console.log(this.data)
              this.fullscreenLoading = false
              this.$router.push({ path: '/resultTarget', query: { data: this.data } })
            })
          }
        }
      } else if (this.method === '2') {
        if (this.radio === 'single') {
          if (this.smile === '') {
            return this.$message.error('Please input a smile')
          } else {
            const data = {}
            data.model = this.modelName
            data.inputsmiles = this.smile
            data.option = this.options[Number(this.method) - 1].label
            this.fullscreenLoading = true
            this.$http.post('/resultTarget', { data: data }).then(res => {
              this.data = res.data
              this.fullscreenLoading = false
              this.$router.push({ path: '/resultTarget', query: { data: this.data } })
            })
          }
        } else {
          if (this.smile === '') {
            return this.$message.error('Please input a smile')
          } else {
            const data = {}
            data.model = this.radio
            data.inputsmiles = this.smile
            data.option = this.options[Number(this.method) - 1].label
            this.fullscreenLoading = true
            this.$http.post('/resultTarget', { data: data }).then(res => {
              this.data = res.data
              this.fullscreenLoading = false
              this.$router.push({ path: '/resultTarget', query: { data: this.data } })
            })
          }
        }
      } else {
        if (this.radio === 'all') {
          return this.$message.error('Methods of Select All which does not support upload file.')
        } else {
          if (this.fileList.length === 0) {
            return this.$message.error('Please upload csv file')
          } else {
            const data = {}
            data.model = this.modelName
            data.file = this.fileList[0]
            data.option = this.options[Number(this.method) - 1].label
            this.fullscreenLoading = true
            this.$http.post('/resultTarget', { data: data }).then(res => {
              this.data = res.data
              this.fullscreenLoading = false
              this.$router.push({ path: '/resultTarget', query: { data: this.data } })
            })
          }
        }
      }
    },
    beforeRemove (file) {
      this.fileList.pop()
    },
    beforeUpload (file) {
      console.log(file.name)
      const formData = new FormData()
      formData.append('filename', file.name)
      formData.append('file', file)
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.batchPredictVisible = false
      //   var that = this
      this.$http.post('/uploadFile', formData, config).then(res => {
        this.fileList.push(res.data)
      })
    },
    getFromIframe (value) {
      this.mol = value
      console.log(this.mol)
    }
  }
}
</script>

<style scoped>
.container{
  display: flex;
  margin: 2% 20%;
  zoom: 0.9;
  font-size: 1.2em !important;
}
.left{
  width: 50%;
}
.right{
  width: 50%;
}
.radio{
  width: 320px;
  text-align: left;
  padding: 1% 0 1% 0;
  font-size: 1.5em;
  display: block;
}
#radio38{
  padding-right: 30px;
}

.leftTitle{
  padding-bottom: 2%;
  min-width: 320px;
  max-width: 320px;
  text-align: left;
}

/* ::v-deep .el-radio__label{
  font-size: 0.7em !important;
} */

</style>
