<template>
    <div class="container" style="font-family: RobotoSlab;">
        <el-card style="margin:0 auto;width:60%">
            <div class="name">Ed Xia</div>
            <div class="email">
               <svg style="padding-right:2%" t="1649400392248" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2499" width="24" height="24"><path d="M512 481.28L940.373333 158.72c-13.653333-6.826667-29.013333-11.946667-44.373333-11.946667h-768c-15.36 0-29.013333 3.413333-42.666667 10.24l426.666667 324.266667z" fill="#333333" p-id="2500"></path><path d="M527.36 532.48c-5.12 3.413333-10.24 5.12-15.36 5.12-5.12 0-10.24-1.706667-15.36-5.12L47.786667 192.853333c-8.533333 13.653333-13.653333 30.72-13.653334 47.786667v542.72c0 51.2 42.666667 93.866667 93.866667 93.866667h768c51.2 0 93.866667-42.666667 93.866667-93.866667V240.64c0-17.066667-5.12-32.426667-11.946667-46.08L527.36 532.48z" fill="#333333" p-id="2501"></path></svg>
                <span style="font-weight:bold">E-mail: &nbsp;</span>
                 <span >448461396@qq.com</span>
            </div>
            <div class="address">
                <svg style="padding-right:2%" t="1649400602187" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3502" width="24" height="24"><path d="M511.913993 63.989249C317.882076 63.989249 159.973123 221.898203 159.973123 415.930119c0 187.323366 315.473879 519.998656 328.890979 534.103813 6.020494 6.364522 14.449185 9.976818 23.221905 9.976818 0.172014 0 0.516042 0 0.688056 0 8.944734 0 17.545439-4.128339 23.393919-11.008903l109.22896-125.054258c145.179909-177.690576 218.629934-314.957836 218.629934-407.845456C864.026877 221.898203 706.117924 63.989249 511.913993 63.989249zM511.913993 575.903242c-88.415253 0-159.973123-71.55787-159.973123-159.973123s71.55787-159.973123 159.973123-159.973123 159.973123 71.55787 159.973123 159.973123S600.329246 575.903242 511.913993 575.903242z" p-id="3503"></path></svg>
                <span style="font-weight:bold">Address: &nbsp;</span>
                <span>South China University of Technology East Circle Road 382, Guangzhou, China</span>
            </div>
            <div class="Institution">
                <svg style="padding-right:2%" t="1649400687205" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4498" width="24" height="24"><path d="M631 602.1c0 32.8-26.6 59.4-59.5 59.4h-119c-32.9 0-59.5-26.6-59.5-59.4V545H128v356.6c0 32.3 26.4 58.4 59.1 58.4h649.8c32.7 0 59.1-26.1 59.1-58.4V545H631v57.1z m181.8-327.4V142c0-43-41.1-78-91.7-78H302.9c-50.6 0-91.7 35-91.7 78v132.6H64V514h329v-90c0-32.8 26.6-59.4 59.5-59.4h119c32.9 0 59.5 26.6 59.5 59.4v90h329V274.7H812.8z m-64 0H275.2V142c0-13 12.4-23.6 27.7-23.6H721c15.3 0 27.7 10.6 27.7 23.6v132.7z" fill="#373838" p-id="4499"></path></svg>
                <span style="font-weight:bold">Institution:&nbsp; </span>
                <span>South China University of Technology</span>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.container{
    width: 100%;
    margin: 5% auto;
}

.name{
    display: flex;
    padding: 1% 0 3% 2%;
    font-size: 1.8em;
    font-weight: bold;
}

.email{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}

.address{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}

.Institution{
    display: flex;
    align-items: center;
    padding: 1% 0 3% 2%;
}
</style>
