<template>
    <div class="container" style="font-family: RobotoSlab;">
        <div class="tableR">
            <el-table
                :data="data.slice((this.page2.currentPage-1)*this.page2.pageSize,this.page2.currentPage*this.page2.pageSize)"
                :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}"
                border
                style="width: 100%">
                <el-table-column
                    prop="pic"
                    align="center"
                    label="Molecule">
                    <template slot-scope="scope">
                        <img v-image-preview style="max-height:150px;" :src="scope.row.pic" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mw"
                    align="center"
                    width="220"
                    label="Molecular Weight">
                </el-table-column>
                <el-table-column
                    prop="logp"
                    align="center"
                    width="220"
                    label="Widman-Crippen LogP">
                </el-table-column>
                <el-table-column
                    prop="hbd"
                    width="100"
                    align="center"
                    label="HBD">
                </el-table-column>
                <el-table-column
                    prop="hba"
                    width="100"
                    align="center"
                    label="HBA">
                </el-table-column>
                <el-table-column
                    prop="qed"
                    width="100"
                    align="center"
                    label="QED">
                </el-table-column>
                <el-table-column
                    prop="rob"
                    width="100"
                    align="center"
                    label="ROB">
                </el-table-column>
                <el-table-column
                    prop="model"
                    align="center"
                    v-if="mode == 'single'"
                    label="Model">
                </el-table-column>
                <el-table-column
                    prop="score"
                    width="150"
                    align="center"
                    v-if="mode == 'single'"
                    label="Score">
                </el-table-column>
            </el-table>
            <el-pagination
               v-if="mode === 'single'"
                style="display:flex;justify-content:end;padding-top:1%"
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="page2.currentPage"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="page2.pageSize"
                layout="slot, sizes, prev, pager, next, jumper"
                :total="page2.total">
                <span style="font-weight:400">Showing {{page2.currentPage}}-{{page2.currentPage * page2.pageSize}} out of {{Number(page2.total).toLocaleString()}} records</span>
              </el-pagination>

            <el-table
            v-if="mode === 'all'"
                :data="data2.slice((this.page.currentPage-1)*this.page.pageSize,this.page.currentPage*this.page.pageSize)"
                :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}"
                 :default-sort="{prop: 'score',order:'descending'}"
                border
                style="width: 100%">
                <el-table-column
                label="Rank"
                width="80"
                align="center"
                  type="index"
                  :index="indexMethod">
                </el-table-column>
                <el-table-column
                    prop="model"
                align="center"
                    label="Model">
                </el-table-column>
                <el-table-column
                    prop="score"
                    sortable
                    width="150"
                align="center"
                    label="Score">
                </el-table-column>
            </el-table>
              <el-pagination
               v-if="mode === 'all'"
                style="display:flex;justify-content:end;padding-top:1%"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.currentPage"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="page.pageSize"
                layout="slot, sizes, prev, pager, next, jumper"
                :total="page.total">
                <span style="font-weight:400">Showing {{page.currentPage}}-{{page.currentPage * page.pageSize}} out of {{Number(page.total).toLocaleString()}} records</span>
              </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
  created () {
    this.handleData()
    console.log(this.data)
  },
  methods: {
    handleCurrentChange (val) { this.page.currentPage = val },
    handleSizeChange (val) { this.page.pageSize = val },
    handleCurrentChange2 (val) { this.page2.currentPage = val },
    handleSizeChange2 (val) { this.page2.pageSize = val },
    indexMethod (index) {
      const pagesize = this.page.pageSize
      const page = this.page.currentPage
      return index + 1 + (page - 1) * pagesize
    },
    handleData () {
      const d = this.$route.query.data
      console.log(d.names.length)
      if (d.names.length === 1) {
        this.mode = 'single'
      } else {
        this.mode = 'all'
      }
      if (d.smile.length === 1 && this.mode === 'all') {
        this.page.total = d.names.length
        const obj = {}
        obj.hba = d.hba
        obj.hbd = d.hbd
        obj.logp = d.logp
        obj.mw = d.mw
        obj.qed = d.qed
        obj.rob = d.rob
        obj.model = []
        obj.pic = 'data:image/svg+xml;base64,' + d.img[0]
        this.data.push(obj)
        d.names.forEach((ele, index) => {
          let obj = {}
          obj.model = ele
          obj.score = d.score[index]
          this.data2.push(obj)
          obj = {}
        })
        this.data2.sort((a, b) => {
          return b.score - a.score
        })
        console.log(this.data2)
      } else {
        d.smile.forEach((ele, index) => {
          let obj = {}
          this.page2.total = d.smile.length
          obj.hba = d.hba[index]
          obj.hbd = d.hbd[index]
          obj.logp = d.logp[index]
          obj.mw = d.mw[index]
          obj.qed = d.qed[index]
          obj.rob = d.rob[index]
          obj.pic = 'data:image/svg+xml;base64,' + d.img[index]
          obj.model = d.names[0]
          obj.score = d.score[index]
          this.data.push(obj)
          obj = {}
        })
      }
    }
  },
  data () {
    return {
      data: [],
      data2: [],
      mode: '',
      page: {
        currentPage: 1,
        pageSize: 5,
        total: 100
      },
      page2: {
        currentPage: 1,
        pageSize: 5,
        total: 100
      }
    }
  }
}
</script>

<style scoped>
.contanier{
    width:100%;
    display: flex;
    align-items: center;
}
.tableR{
    margin: 0 auto;
    width: 100%;
}
::v-deep .el-table--border::after,.el-table::before{
    background: none !important;
}
</style>
