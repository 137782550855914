<template>
    <div class="container" style="font-family: RobotoSlab;">
        <!-- <iframe id="text" src="/static/index.html" width="80%" height="500" frameborder="0"></iframe> -->
        <div class="left">
          <div class="mtehod" style="font-size:1.5em ;font-family: RobotoSlab;text-align:left;border-bottom:7px solid #1d252e;margin-bottom:1%;padding-top:1%;line-height:2">Compound Search</div>
        <!-- <div class="jsmol"> -->
            <!-- <div  style="text-align:left;" class="jsmolTitle"><span style="margin-left:3%">JSME Editor</span></div> -->
            <div class="main">
                <iframe id="text" src="/static/index.html" width="100%" height="360px" frameborder="0"></iframe>
            </div>
        <!-- </div> -->
        <div class="smiles">
            <div style="font-size:1.5em ;font-family: RobotoSlab;text-align:left;border-bottom:7px solid #1d252e;margin-bottom:1%;margin-top:-7%;padding-top:1%;line-height:2">SMILES</div>
            <!-- <div class="title" style="text-align:left;padding-bottom:2%;font-weight:bold">SMILE</div> -->
            <el-input v-model="mol" placeholder="Enter..."></el-input>
            <el-button size="large" style="font-family: RobotoSlab;display:flex;margin-top:2%;background:#1d252e;color:white;font-size:1.4em" @click="query">Submit</el-button>
        </div>
        </div>
        <div class="right">
          <!-- <div v-show="show" style="display:flex;justify-content:flex-end;padding-right:1%"><el-button  size="small"  type="primary" icon="el-icon-download" @click="downloadFile">csv</el-button></div> -->
           <el-table
                v-loading="loading"
                    v-show="show"
                    :data="data"
                    :header-cell-style="{background: '#222a35',color:'white',fontSize:'1.2em',fontWeight:'300',textAlign:'center'}"
                    >
                    <el-table-column
                    prop="smiles"
                    :show-overflow-tooltip="true"
                    width="180"
                    align="center"
                    label="2D Structure"
                    >
                    <template slot-scope="scope">
                        <img v-image-preview style="width:100%; max-height:100px;" :src="scope.row.pic" alt="">
                        <!-- <div>
                            {{scope.row.smiles}}
                        </div> -->
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="score"
                    label="Score"
                    align="center"
                    width="100"
                    >
                    </el-table-column>
                    <el-table-column
                    width="100"
                    prop="phase"
                    label="Phase"
                    align="center"
                    >
                    </el-table-column>
                    <el-table-column
                    property="type"
                    align="center"
                    width="180"
                    label="Activity Type">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.type + '' + scope.row.relation + ''  +scope.row.value + '' + scope.row.units}}
                        </div>
                    </template>
                    </el-table-column>

                    <el-table-column
                    width="500"
                    prop="assayDescription"
                    label="Assay Description">
                    </el-table-column>

                    <el-table-column
                    prop="assayType"
                    width="120"
                    align="center"
                    label="Assay Type">
                    </el-table-column>

                    <el-table-column
                    prop="targetName"
                    width="180"
                    align="center"
                    label="Target Name">
                    </el-table-column>

                    <el-table-column
                    prop="targetOrganism"
                    width="180"
                    align="center"
                    label="Target Organism">
                    </el-table-column>

                    <!-- <el-table-column
                    prop="comment"
                    width="100"
                    label="Comment">
                    </el-table-column> -->

                </el-table>

                <el-pagination
                    v-show="show"
                    style="display:flex;justify-content:end;padding-top:1%"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="page.pageSize"
                    layout="slot, sizes, prev, pager, next, jumper"
                    :total="page.total">
                    <span style="font-weight:400;font-size:1em">Showing {{page.currentPage}}-{{page.currentPage * page.pageSize}} out of {{Number(page.total).toLocaleString()}} records</span>
                </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      mol: '',
      loading: '',
      data: [],
      databaseName: '',
      page: {
        currentPage: 1,
        pageSize: 5,
        total: 400
      }
    }
  },
  beforeDestroy () {
    window.localStorage.clear()
  },
  created () {
    window.getFromIframe = this.getFromIframe
  },
  methods: {
    downloadFile () {
      this.fullscreenLoading = true
      const a = document.createElement('a')
      console.log(this.BASEURL)
      a.href = this.BASEURL + '/downloadFile/' + this.nowBac + '.csv'
      a.click()
      this.fullscreenLoading = false
    },
    handleCurrentChange (current) {
      this.page.currentPage = current
      this.onload(this.page)
    },
    handleSizeChange (size) {
      this.page.pageSize = size
      this.onload(this.page)
    },
    createDtabase () {
      const now = new Date()
      this.databaseName = now.getTime()
      window.localStorage.setItem('database', this.databaseName)
    },
    query () {
      if (this.mol === '') {
        this.$message.error('Please enter smiles or generate smiles by jsme editor')
      } else {
        this.show = true
        this.onload(this.page)
      }
    },
    getFromIframe (value) {
      this.mol = value
    },
    onload (page) {
      this.loading = true
      console.log(this.mol)
      this.$http.post('/getScore', { prop: this.mol, page: page }).then(res => {
        if (res.data.return_code === '200') {
          const newList = []
          this.page.total = res.data.total
          console.log(res.data)
          res.data.result.forEach(ele => {
            const obj = {}
            obj.smiles = ele[2]
            obj.phase = ele[1]
            obj.type = ele[3]
            obj.relation = ele[4]
            obj.value = ele[5]
            obj.units = ele[6]
            obj.assayDescription = ele[7]
            obj.assayType = ele[8]
            obj.targetName = ele[9]
            obj.targetOrganism = ele[10]
            obj.comment = ele[11]
            const url = 'data:image/svg+xml;base64,' + ele[12]
            obj.score = ele[13]
            if (obj.score !== 'null') {
              obj.score = obj.score.toFixed(2)
            }
            obj.pic = url
            newList.push(obj)
          })
          this.data = newList
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    zoom: 0.9;
}

.jsmol{
    border: 1px solid #f0f0f0;
}

.jsmolTitle{
    background: #1d252e;
    color: white;
    line-height: 3;
    font-size: 1em;
}

.smiles{
    padding-top: 4%;
}

.left{
  width: 22%;padding-right: 1%;
  display: inline-block;
}
.right{
  border:2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  width: 78%;
  display: inline-block;
}
::v-deep .el-pagination{
  justify-content: flex-end;
}

::v-deep .el-table .cell{
  word-break: keep-all;
}
</style>
