import { render, staticRenderFns } from "./browse.vue?vue&type=template&id=496db15d&scoped=true&"
import script from "./browse.vue?vue&type=script&lang=js&"
export * from "./browse.vue?vue&type=script&lang=js&"
import style0 from "./browse.vue?vue&type=style&index=0&id=496db15d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496db15d",
  null
  
)

export default component.exports