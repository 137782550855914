import Vue from 'vue'
import {
  Button,
  Header,
  Container,
  Footer,
  Menu,
  MenuItem,
  Submenu,
  Main,
  Collapse,
  CollapseItem,
  Image,
  Card,
  Input,
  Icon,
  Table,
  TableColumn,
  Pagination,
  MenuItemGroup,
  Link,
  Tooltip,
  Loading,
  Dialog,
  TabPane,
  Tabs,
  Message,
  Radio,
  RadioGroup,
  Upload,
  Select,
  Option
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.component(Pagination.name, Pagination)
Vue.component(Table.name, Table)
Vue.component(TabPane.name, TabPane)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Upload.name, Upload)

Vue.use(Option)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Button)
Vue.use(Header)
Vue.use(Container)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Main)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Image)
Vue.use(Card)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(MenuItemGroup)
Vue.use(Link)
Vue.use(Tooltip)
Vue.use(Loading)
Vue.use(Dialog)
Vue.prototype.$message = Message
